import React from 'react'

const BussniessCard = () => {

    return (
        <>
            <div>
                <h>helloo</h>
            </div>
        </>
    )
}

export default BussniessCard

