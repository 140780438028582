import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // For making HTTP requests
import 'bootstrap/dist/css/bootstrap.min.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import help from './../img/Group 98.png';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle } from 'react-icons/fa';  // Green check icon


const HelpModal = () => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    // Toggle the modal visibility
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const mobilePattern = /^\d{10}$/;
        const cleanedMobile = formData.mobile.trim(); // Remove leading and trailing spaces

        if (!mobilePattern.test(cleanedMobile)) {
            toast.error('Mobile number must be exactly 10 digits.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeButton: false,
            });
            return; // Prevent form submission if validation fails
        }


        // Send form data to the server
        const response = await fetch('https://squibfactory.com/apis/Contact_form.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const data = await response.json();

        // Check for success or failure and show the corresponding toast message

        // In your handleSubmit function
        if (data.success) {
            toast.success(
                <div>
                    <FaCheckCircle style={{ color: 'green', marginRight: '8px' }} />
                    {data.message}
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeButton: false,
                    icon: false, // Disables default icon, we're using a custom one
                }
            );
            // Reset form and close modal after successful submission
            setFormData({
                name: '',
                email: '',
                mobile: '',
                message: ''
            });
            setShow(false); // Hide the modal
        } else {
            toast.error(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeButton: false,
            });
        }
        console.log("Mobile input value:", formData.mobile); // Log the value to the console

    };

    return (
        <>
            {/* Help Icon Button */}
            <div className="help-icon" onClick={handleShow} style={{ background: 'transparent' }}>
                <span className="help-text">
                    <img src={help} alt="Help Icon" style={{ width: '70px', height: '70px' }} />
                </span>
            </div>

            {/* Modal with Custom Position */}
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="custom-modal"
                backdrop="static"
                keyboard={false}
                style={{ padding: '1px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>How can I help you?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="helpForm" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        {/* Change 'mobile' to 'phoneNumber' */}
                        <Form.Group className="mb-3" controlId="phoneNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                type="text"  // Or 'tel', but not 'number'
                                placeholder="Enter your mobile number"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                pattern="^\d{10}$" // Ensure it accepts only 10 digits
                                title="Mobile number should contain exactly 10 digits"
                            />

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter your message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        {/* Add a hidden field for 'source' if it's expected */}
                        {/* <input type="hidden" name="source" value="modal" /> Adjust value as needed */}

                        <Button className="float-right submit-btn-home" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>

                </Modal.Body>
            </Modal>

            {/* Toast notifications container with autoClose set to 5 seconds */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                closeButton={false}
            />



            {/* Custom styles */}
            <style jsx>{`
        .help-icon {
          position: fixed;
          bottom: 20px;
          right: 20px;
          background-color: #007bff;
          color: white;
          padding: 10px 15px;
          border-radius: 50px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 1050;
          transition: background-color 0.3s ease-in-out;
        }

        .help-icon:hover {
          background-color: #0056b3;
        }

        .help-text {
          font-size: 14px;
        }

        /* Custom modal position */
        .custom-modal {
          position: fixed;
          bottom: 20px;
          right: 20px;
          margin: 0;
          width: 300px; /* Adjust as needed */
          max-width: 100vw; /* Ensure responsiveness */
          z-index: 1051;
          animation: slideIn 0.3s ease-out;
        }

        @keyframes slideIn {
          from {
            transform: translateY(100%);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
        </>
    );
};

export default HelpModal;
