// src/pages/Home.js
import React from "react";
import HeroSection from "../components/HeroSection";

function Home() {
  return (
    <>
    
      <HeroSection />
      {/* Add other sections for the homepage here */}
    </>
  );
}

export default Home;
