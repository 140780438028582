import React from 'react';
import { Card, Button, Row, Col, Container, Carousel } from 'react-bootstrap';
import { AccountBalance, TrendingUp, Group } from '@mui/icons-material'; // MUI Icons
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import Slider from 'react-slick';


import com from '../../img/mm/4 1.png';
import com2 from '../../img/mm/4 2.png';
import com3 from '../../img/mm/4 3.png';




// Services Array
const services = [
    {
        img: com,
        title: 'Digital Marketing Services',
        text: 'It’s not just posting and creating content that explains your brand, rather it includes delivering that content to the right audience.',
        link: 'Digital-Marketing-Services'
    },
    {
        img: com2,
        title: 'Offline Marketing Services',
        text: 'Delivering content to the right audience to convert them into your clientele.',
        link: 'Offline-Marketing-Services'
    },
    {
        img: com3,
        title: 'Financial Services',
        text: 'Effective financial strategies to grow your business.',
        link: 'Financial-Services'
    },
    {
        img: com,
        title: 'HR Services',
        text: 'Enhance your online visibility and attract more customers with tailored SEO strategies.',
        link: 'hr-services'
    },
    {
        img: com2,
        title: 'Graphic Designing Services',
        text: 'Engage your audience with high-quality content crafted by expert designers.',
        link: 'graphic-designing-services'
    },
    {
        img: com3,
        title: 'Web Development',
        text: 'Maximize ROI with effective web strategies.',
        link: 'web-development'
    }
];

// Slick settings
const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
};


const Marketing = () => {
    const navigate = useNavigate();

    // const handleRedirect = () => {
    //    navigate('/Offline-Marketing-Services');
    // };
    const handleRedirect = (path) => {
        navigate(path);
    };
    return (
        <>

            {/* new section */}
            <section className=" diagonal-card-section" id="second-section">
                <Container >
                    {/* Title Section */}
                    <Row className='our-services-text-home'>
                        <Col xs={10} md={6} lg={6} >

                            <div className=" text-heading-servics mb-4">
                                <h2>Our Service</h2>
                                <h3 className="text-primary32">We Can Help You Solve Your Problems Through Our Services</h3>
                                <p>
                                    Uniqueness is one of the main reasons that separates us from others.
                                    Our team has worked tremendously in all aspects to create an overall
                                    growth projection for our clients.
                                </p>
                            </div>

                        </Col>
                    </Row>
                    {/* Diagonal Cards Carousel */}
                    <Carousel className='desktop-slider-home'>
                        <Carousel.Item style={{ overflow: 'visible' }}>
                            <Row className="diagonal-layout " style={{ overflow: 'visible' }}>
                                {/* Card 1 */}
                                <Col md={4} className="mb-4 card-1">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Digital Marketing Services</Card.Title>
                                            <Card.Text>
                                                It’s not just posting and creating content that explains your brand, rather it majorly includes delivering that content to the right audience to convert them into your clientele.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/Digital-Marketing-Services')} className="btn btn-link">Learn more</a>
                                            {/* <Link to="Digital-Marketing-Services" className="btn btn-link">Learn more</Link> */}
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col md={4} className="mb-4 card-2">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com2} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Offline Marketing Services</Card.Title>
                                            <Card.Text>
                                                It’s not just posting and creating content that explains your brand, rather it majorly includes delivering that content to the right audience to convert them into your clientele.
                                            </Card.Text>
                                            {/* <Link to="Offline-Marketing-Services" className="btn btn-link">Learn more</Link> */}
                                            <a onClick={() => handleRedirect('/Offline-Marketing-Services')} className="btn btn-link">Learn more</a>

                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 3 */}
                                <Col md={4} className="mb-4 card-3">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com3} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Financial Services</Card.Title>
                                            <Card.Text>
                                                It’s not just posting and creating content that explains your brand, rather it majorly includes delivering that content to the right audience to convert them into your clientele.
                                            </Card.Text>
                                            {/* <Link to="Financial-Services" className="btn btn-link">Learn more</Link> */}
                                            <a onClick={() => handleRedirect('/Financial-Services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>

                        {/* Second Slide */}
                        <Carousel.Item>
                            <Row className="diagonal-layout">
                                {/* Card 4 */}
                                <Col md={4} className="mb-4 card-1">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> HR Services</Card.Title>
                                            <Card.Text>
                                                Enhance your online visibility and attract more customers with our tailored SEO strategies.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/hr-services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 5 */}
                                <Col md={4} className="mb-4 card-2">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com2} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Graphic Designing Services</Card.Title>
                                            <Card.Text>
                                                Engage your audience with high-quality, engaging content crafted by our expert writers.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/graphic-designing-services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 6 */}
                                <Col md={4} className="mb-4 card-3">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title>Web development</Card.Title>
                                            <Card.Text>
                                                Maximize your ROI with our effective pay-per-click advertising strategies.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/web-development')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        {/* 3333333 Slide */}
                        <Carousel.Item>
                            <Row className="diagonal-layout">
                                {/* Card 4 */}
                                <Col md={4} className="mb-4 card-1">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Legal Services</Card.Title>
                                            <Card.Text>
                                                Enhance your online visibility and attract more customers with our tailored SEO strategies.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/legal-services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 5 */}
                                <Col md={4} className="mb-4 card-2">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com2} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title> Accounting  Services</Card.Title>
                                            <Card.Text>
                                                Engage your audience with high-quality, engaging content crafted by our expert writers.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/accounting-services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 6 */}
                                <Col md={4} className="mb-4 card-3">
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img src={com} height={200} className='cards-img-mmarketing' />
                                        <Card.Body>
                                            <Card.Title>Printing Services</Card.Title>
                                            <Card.Text>
                                                Maximize your ROI with our effective pay-per-click advertising strategies.
                                            </Card.Text>
                                            <a onClick={() => handleRedirect('/printing-services')} className="btn btn-link">Learn more</a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </section>




            <section className="diagonal-card-section">
                <Container>
                    {/* Mobile Slider */}
                    <div className="mobile-slider-home diagonal-layout">
                        <Slider {...slickSettings}>
                            {services.map((service, index) => (
                                <div key={index}>
                                    <Card className="h-100 custom-card-marketing">
                                        <Card.Img
                                            src={service.img}
                                            height={200}
                                            className="cards-img-mmarketing"
                                        />
                                        <Card.Body>
                                            <Card.Title>{service.title}</Card.Title>
                                            <Card.Text>{service.text}</Card.Text>
                                            <Link to={service.link} className="btn btn-link">
                                                Learn more
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </Slider>
                    </div>


                </Container>
            </section>


        </>

    )
}

export default Marketing
